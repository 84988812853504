<template>
    <v-dialog v-model="dialog" width="800" scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on" id="profSelect">
                <v-icon>mdi-human-male-board-poll</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 grey lighten-2 justify-space-between">
                <span>Формируемые квалификации</span>
                <v-btn fab small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text class="mt-3" v-if="!loading">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-center">Квалификация</th>
                            <th class="text-center">Выбрано трудовых функций</th>
                            <th class="text-center">Формируется, %</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="qualification in qualifications" :key="qualification.id">
                            <td>
                                <div class="text-body-1 font-weight-bold">{{ qualification.name }}</div>
                                <div v-for="workFunction in qualification.workFunctions" :key="workFunction.id"
                                    class="ml-3 grey--text text--darken-4">
                                    <v-icon color="green" v-if="workFunction.used">mdi-check</v-icon>
                                    <v-icon color="red" v-else>mdi-close</v-icon>
                                    {{ workFunction.name }} ({{ Math.round(workFunction.percent??0) }}%)
                                </div>
                            </td>
                            <td class="text-h6 text-center">{{ Math.ceil(qualification.progress*100)/100 }} %</td>
                            <td class="text-h6 text-center">

                                    {{ Math.ceil(qualification.workFunctions.reduce((partialSum, a) => partialSum + (a.percent??0), 0)/qualification.workFunctions.length) }} %

                                
                            </td>
                            
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-card-text class="text-center" v-else>
                <div class="text-h5 ma-6">Загрузка...</div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ["eduProgId"],
    data() {
        return {
            qualifications: [],
            dialog: false,
            loading: true
        }
    },
    watch: {
        dialog(val) {
            if (val === true) {
                this.fetchData()
            }

        }
    },
    methods: {
        fetchData() {
            this.loading = true
            this.$http.get('prof-stand/qualifications', {
                params: {
                    eduProgId: this.eduProgId
                }
            }).then(response => {
                this.qualifications = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    }

}
</script>