var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-sheet',{staticClass:"pa-1",staticStyle:{"position":"sticky","top":"12px"},attrs:{"outlined":"","rounded":""}},[_vm._v(_vm._s(_vm.profStand.name))])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"11"}},_vm._l((_vm.profStand.generalWorkFunctions),function(generalWorkFunction){return _c('v-sheet',{key:'gf' + generalWorkFunction.id,staticClass:"my-3 py-2 ml-0 pr-3",attrs:{"outlined":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"py-1 pr-0",attrs:{"cols":"2"}},[_c('v-sheet',{staticClass:"pa-2",staticStyle:{"position":"sticky","top":"12px"},attrs:{"outlined":"","rounded":""}},[_c('b',[_vm._v("ОТФ:")]),_vm._v(" "+_vm._s(generalWorkFunction.name)+" ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},_vm._l((generalWorkFunction.workFunctions),function(workFunction){return _c('v-row',{key:'wf' + workFunction.id,staticClass:"ma-0"},[_c('v-col',{staticClass:"py-1 pr-0",attrs:{"cols":"3"}},[_c('v-sheet',{staticClass:"pa-2 d-flex justify-space-between",class:_vm.selectedFunctions.findIndex(
                      (el) => el == workFunction.id
                    ) !== -1
                        ? 'deep-purple lighten-4'
                        : '',staticStyle:{"position":"sticky","top":"12px"},attrs:{"elevation":"1","outlined":"","rounded":""}},[_c('div',[_c('b',[_vm._v("ТФ:")]),_vm._v(" "+_vm._s(workFunction.name))]),_c('v-checkbox',{staticClass:"ma-n2 mr-n3 pl-3",attrs:{"value":workFunction.id,"multiple":""},model:{value:(_vm.selectedFunctions),callback:function ($$v) {_vm.selectedFunctions=$$v},expression:"selectedFunctions"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"5px","right":"5px"}},[_c('comment',{attrs:{"comment":_vm.getComment('workFunction', workFunction)}})],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"9"}},_vm._l((workFunction.workActions),function(workAction){return _c('v-row',{key:'wa' + workAction.id,staticClass:"ma-0"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"5"}},[_c('v-sheet',{staticClass:"pa-2",class:_vm.checkSelect(workFunction.id)
                            ? 'orange lighten-4'
                            : 'orange lighten-5',staticStyle:{"position":"sticky","top":"12px"},attrs:{"outlined":"","rounded":"","elevation":_vm.checkSelect(workFunction.id) ? 1 : 0}},[_c('b',[_vm._v("ТД:")]),_vm._v(" "+_vm._s(workAction.name)+" "),(_vm.editMode)?_c('v-btn',{staticStyle:{"position":"absolute","right":"-16px","top":"calc(50% - 16px)"},attrs:{"fab":"","x-small":"","color":"green lighten-2","dark":""},on:{"click":function($event){return _vm.uzAddDialogOpen(workAction, workFunction)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),_c('br'),_c('div',{staticClass:"text-right"},[_c('comment',{attrs:{"comment":_vm.getComment('workAction', workAction)}}),(_vm.editMode)?_c('skills-sort',{attrs:{"skills":workAction.skills,"workActionId":workAction.id},on:{"update:skills":function($event){return _vm.$set(workAction, "skills", $event)}}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"7"}},_vm._l((workAction.skills),function(skill){return _c('v-sheet',{key:'s' + skill.id,staticClass:"pa-2 my-1",class:[_vm.checkSelect(workFunction.id)
                            ? ' lighten-4'
                            : ' lighten-5', skill.type == 'um' ? 'green' : 'blue'],attrs:{"elevation":_vm.checkSelect(workFunction.id) ? 1 : 0,"outlined":"","rounded":""}},[(skill.type == 'um')?_c('b',[_vm._v("У:")]):_c('b',[_vm._v("З:")]),_vm._v(" "+_vm._s(skill.name)+" "),(_vm.editMode)?_c('div',{staticClass:"text-right mr-3"},[(1 !== 1)?_c('v-btn',{attrs:{"icon":"","x-small":"","color":"blue darken-3"},on:{"click":function($event){return _vm.skillDialogEditOpen(skill)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('DeleteBtn',{attrs:{"actionHref":'prof-stand/skill-delete?id=' + skill.id + '&type=' + skill.type,"text":'Вы действительно хотите удалить:' + skill.name},on:{"remove":function($event){workAction.skills.splice(workAction.skills.findIndex(el => el.id == skill.id), 1)}}})],1):_vm._e()])}),1)],1)}),1)],1)}),1)],1)],1)}),1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px","scrollable":""},model:{value:(_vm.skillAddComponent),callback:function ($$v) {_vm.skillAddComponent=$$v},expression:"skillAddComponent"}},[_c('skillAddComponent',{attrs:{"profStandId":_vm.profStand.id,"td":_vm.tdSelected,"tf":_vm.tfSelected,"listSkills":_vm.tfSelected.skills,"dialog":_vm.skillAddComponent},on:{"add":function($event){return _vm.skillAdd($event)},"addList":_vm.fetchData,"close":function($event){_vm.skillAddComponent = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px","scrollable":""},model:{value:(_vm.dialogSkillEdit),callback:function ($$v) {_vm.dialogSkillEdit=$$v},expression:"dialogSkillEdit"}},[_c('skillEditComponent',{attrs:{"skill":_vm.skillSelected,"isEdit":_vm.editMode},on:{"close":function($event){_vm.dialogSkillEdit = false},"update":function($event){return Object.assign(_vm.skillSelected, $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }