<template>
  <v-card>

    <v-card-title class="white--text" style="background-color:#365d84" >
      <v-row align="center">
        <v-col>
          <div>
            Добавить Умение или Знание к ТД:
          </div>
          <div class="text-body-1">
            {{td.name}}
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn fab small color="red" dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <div>
    </div>
<v-card-text>
  <!-- <v-tabs center-active v-model="tab" fixed-tabs>
    <v-tab>Новое</v-tab>
    <v-tab>Выбрать из трудовой функции</v-tab>
  </v-tabs> -->
  <v-tabs-items v-model="tab">
    <v-tab-item>
      <v-container class="text-center">
        <v-btn-toggle rounded v-model="UZObject.type" :color="UZObject.type == 'um' ? 'green' : 'blue'" class="my-3" mandatory>
          <v-btn :value="'um'" >Умение</v-btn>
          <v-btn :value="'zn'" >Знание</v-btn>
        </v-btn-toggle>

            <v-textarea outlined label="Введите название" class="mt-5" auto-grow rows="3" dense v-model="UZObject.name">
            </v-textarea>

            <div style="border:1px dashed grey; border-radius:20px" class="pa-2" v-if="searchSkills && searchSkills.length > 0">
              <div class="text-body-1 text-left black--text font-weight-medium mb-2 ml-4">

                Похожие {{ UZObject.type == 'um' ? 'умения' : 'знания' }}</div>
              <v-divider />
              <v-simple-table class="text-left " style="max-height:200px; overflow:auto">
                <tbody>
                  <tr v-for="(item, index) in searchSkills" :key="index">
                    <td>
                      {{ item.name }}

                    </td>
                    <td>
                      <v-btn @click="UZObject.name = item.name" small color="primary ml-3">Выбрать</v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip :color="item.type == 'um' ? 'green lighten-4' : 'blue lighten-4'" class="my-3">{{
                    item.type ==
                      'um' ? 'Умение' : 'Знание'
                  }}</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>


          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-alert class="ma-5" border="top" colored-border type="info" elevation="2">
            Зеленым цветом выделены умения и знания которые уже используются в других трудовых действиях
          </v-alert>
          <v-simple-table>
            <tbody>
              <tr v-for="(skill, index) in tfSkills" :key="index">
                <td>
                  <v-checkbox :value="skill.id" v-model="selectedSkills" multiple />
                </td>
                <td>
                  <v-chip :color="skill.type == 'um' ? 'green lighten-4' : 'blue lighten-4'">{{
                    skill.type == 'um' ?
                      'Умение' : 'Знание'
                  }}</v-chip>
                </td>
                <td class="text-left">

                  <span :class="allSkills.findIndex(el => el.name == skill.name) != -1 ? 'green--text' : ''">{{ skill.name }}</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer/>
      <v-btn
          color="primary"
          @click="tab==0?createUZ():addSkillsForList()"
          :loading="loaded"
      >
        Сохранить
      </v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "DialogUZAdd",
  data() {
    return {
      name: null,
      toggleBtns: 0,
      loaded: false,
      UZObject: {
        name: '',
        type: 'um'
      },
      addError: {

      },
      allSkills: [],
      type: null,
      skillsloading: true,
      skillList: [],
      tab: 1,
      selectedSkills: []
    }
  },
  mounted() {
    this.fetchAllSkills()
  },
  props: [
    'td', 'profStandId', 'listSkills', 'dialog', 'tf'
  ],
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    similarity(s1, s2) {
      if(!s1 || !s2) return 0
      var longer = s1;
      var shorter = s2;
      if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
      }
      var longerLength = longer.length;
      if (longerLength == 0) {
        return 1.0;
      }
      return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
    },
    editDistance(s1, s2) {
      s1 = s1.toLowerCase();
      s2 = s2.toLowerCase();

      var costs = new Array();
      for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
          if (i == 0)
            costs[j] = j;
          else {
            if (j > 0) {
              var newValue = costs[j - 1];
              if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                  costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
        if (i > 0)
          costs[s2.length] = lastValue;
      }
      return costs[s2.length];
    },
    createUZ() {
      this.loaded = true
      this.$http.post('prof-stand/skill-create?workActionId=' + this.td.id, this.UZObject)
        .then(res => {
          console.log(res)
          this.$emit('add', res.data)
          this.UZObject.name = ''
          this.UZObject.type = 'um'
          this.$emit('close')
        })
        .catch(err => {
          console.log(err)
          this.addError = err.response.data
        }).finally(() => {
          this.loaded = false
        })

    },
    fetchAllSkills() {
      this.loaded = true
      this.$http.get('prof-stand/get-all-skills?profStandId=' + this.profStandId)
        .then(res => {
          console.log(res.data)
          this.allSkills = res.data
          // if(this.tfSkills.length > 0){
          //   this.tab = 1
          // }else{
          //   this.tab = 0
          // }
          if(this.tf.skills.length > 0){
            this.tab = 1
          }else{
            this.tab = 0
          }
        }).catch(err => {
          console.log(err)
          this.addError = err.response.data
        }).finally(() => {
          this.loaded = false
        })
    },
    async saveSkill(skill) {
      try {
        const res = await this.$http.post('prof-stand/skill-create?workActionId=' + this.td.id, skill);
        this.$emit('add', res.data);
      } catch (err) {
        alert(err.response.data);
      }
    },
    addSkillsForList() {
      this.loaded = true
      let skills = []
      for (let skillId of this.selectedSkills) {
        let skill = this.tfSkills.find(el => el.id == skillId)
        let newSkills = { name: skill.name, type: skill.type }
        skills.push(newSkills)

      }
      let that = this
      Promise.all(skills.map(that.saveSkill)).then(() => {
        that.loaded = false

      }).finally(() => {
        that.$emit('close')
      })

    }
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.fetchAllSkills()
        this.selectedSkills=[]
      }
    }
  },
  computed: {
    tfSkills() {
      let data = this.tf.workActions.map(el => el.skills).flat(1)
      if(this.listSkills)
      {
        data = [...this.listSkills, ...data]
      }
      let result = []
      for (var el of data) {
        if (result.findIndex(skill => skill.name == el.name && skill.type == el.type) === -1) {
          result.push(el)
        }
      }
      return result.filter(el => this.td.skills.findIndex(el1 => el1.name == el.name) === -1)
    },
    searchSkills() {
      let result = []
      for (var el of this.allSkills) {
        if (result.findIndex(skill => skill.name == el.name && skill.type == el.type) === -1) {
          result.push(el)
        }
      }
      return result.filter(el => (this.similarity(el.name, this.UZObject.name) > 0.9 || el.name.toLowerCase().includes(this.UZObject.name.toLowerCase())) && el.type == this.UZObject.type && el.name != this.UZObject.name)
    },
    inputVal() {
      return this.allSkills.filter(el => el.type == this.UZObject.type)
    }

  }
}
</script>

<style scoped>

</style>