<template>
  <v-card elevation="12" rounded>
    <v-card-title class="white--text" style="background-color:#365d84">
      <v-row>
        <v-col>
          {{ genWorkFunc.name }}
        </v-col>
        <v-col cols="auto">
          <v-btn fab @click="$emit('close')" color="red" small dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="black--text">
      <div class="text-body-1 mt-5" >Код: {{ genWorkFunc.code }}</div>
      <div class="text-body-1 mt-5">  Уровень квалификации: {{ genWorkFunc.level }}</div>

        <div>
          <table border="1" width="100%" class="mt-5">
            <tbody>
              <tr>
                <td class="pa-2">Возможные наименования должностей, профессий</td>
                <td class="pa-2">
                  <template v-if="genWorkFunc.possibleJobTitles instanceof Array">
                    <div v-for="(title, index) in genWorkFunc.possibleJobTitles" :key="index">{{ title }}</div>
                  </template>
                  <template v-else>
                    <div>{{ genWorkFunc.possibleJobTitles }}</div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" class="mt-5" width="100%">
            <tbody>
              <td class="pa-2">Требования к образованию и обучению</td>
              <td class="pa-2">
                <template v-if="genWorkFunc.educationalRequirements instanceof Array">
                  <div v-for="(req, index) of genWorkFunc.educationalRequirements" :key="index">
                    {{ req }}
                  </div>

                </template>
                <template v-else>
                  <div>{{ genWorkFunc.educationalRequirements }}</div>
                </template>

              </td>
              <tr>
                <td class="pa-2">Особые условия допуска к работе</td>
                <td class="pa-2">
                  <template v-if="genWorkFunc.specialConditionsForAdmissionToWork instanceof Array">
                    <p v-for="(education, index) in genWorkFunc.specialConditionsForAdmissionToWork" :key="index">
                      {{ education }}
                    </p>

                  </template>
                  <template v-else>
                    <p>{{ genWorkFunc.specialConditionsForAdmissionToWork }}</p>
                  </template>

                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-3 text-body-1">Дополнительные характеристики</div>
          <table border="1" width="100%" >
            <tbody>
              <tr v-for="(classElement, index) in genWorkFunc.classes" :key="index">
                <td class="pa-2">{{ types[classElement.typeId] }}</td>
                <td class="pa-2">
                    {{ classElement.code }}
                </td>
                <td class="pa-2">
                    {{ classElement.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['genWorkFunc'],
  data() {
    return {
        types:{1: "ОКЗ", 2: "ОКВЭД", 3: "ОКСО", 4:"ОКПДТР",5: "ЕКС",6:"ЕТКС"}
      }
    }
  
}
</script>

<style scoped>
</style>
