<template>
  <v-card>
    <v-card-title class="white--text" style="background-color:#365d84">
      <v-row align="center">
        <v-col>
          {{ workFunc.name }}
        </v-col>
        <v-col cols="auto">
          <v-btn fab @click="$emit('close')" color="red" dark small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>

      <v-list class="text-body-1">
        Наименование:
        <v-list-item>
          {{  workFunc.name }}
        </v-list-item>
        Код:
        <v-list-item>
          {{  workFunc.code }}
        </v-list-item>
        Уровень квалификации:
        <v-list-item>
          {{ workFunc.subQualification }}
        </v-list-item>
      </v-list>

        <table  border="1" width="100%">
          <tbody>
            <tr>
              <td class="pa-2">Трудовые действия</td>
              <td class="pa-2">
                <p v-for="(func,index) in workFunc.workActions" :key="index">
                  {{ func }}
                </p>
              </td>
            </tr>
            <tr>
              <td class="pa-2">Необходимые умения</td>
              <td class="pa-2">
                <p v-for="(func,index) in workFunc.skills" :key="index">{{ func }}</p>
              </td>
            </tr>
            <tr>
              <td class="pa-2">Необходимые знания</td>
              <td class="pa-2">
                <p v-for="(func,index) in workFunc.knowledges" :key="index">{{ func }}</p>
              </td>
            </tr>
          </tbody>
        </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['workFunc'],
  name: "workFuncModal"
}
</script>

<style scoped>
</style>
