<template>
  <v-dialog scrollable v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark x-small icon v-bind="attrs" v-on="on" class="mr-1">
        <v-icon>mdi-sort</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Сортировка умений и знаний
        <v-spacer />
        <v-btn fab small @click="dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <draggable v-bind="dragOptions" v-model="skillsList" @start="drag=true" @end="drag=false" group="a" item-key="id">
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-sheet outlined rounded class="pa-2 my-1 lighten-4" style="position: relative; cursor:pointer;"
              color="green" v-for="(skill,index) in skillsList"
              :key="'um' + skill.id" elevation="1">
              <v-chip color="primary" small class="mr-3 mb-3">{{index+1}}</v-chip>
              <b >У:</b> {{ skill.name }}
            </v-sheet>
          </transition-group>
        </draggable>
        <draggable v-bind="dragOptions" v-model="knowledgeList" @start="drag=true" @end="drag=false" group="b" item-key="id">
          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-sheet outlined rounded class="pa-2 my-1 lighten-4" style="position: relative; cursor:pointer;"
              color="blue" v-for="(skill,index) in knowledgeList"
              :key="'zn' + skill.id" elevation="1">
              <v-chip color="primary" small class="mr-3 mb-3">{{index+1}}</v-chip>
               <b>З:</b> {{ skill.name }}
            </v-sheet>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :loading="saving">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['skills','workActionId'],
  data() {
    return {
      drag: false,
      saving:false,
      dialog: false,
      skillsList: [],
      knowledgeList: [],
      skillsCopy: []
    }
  },
  computed:{
    dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost",
        };
      },
      
      
  },
  methods: {
    save() {
      this.saving=true
      let order = [].concat(this.skillsList.map(el => el.id), this.knowledgeList.map(el => el.id));
      
      this.$http.post('prof-stand/skills-order?workActionId='+this.workActionId,order).then((res)=>{
        this.$emit('update:skills', res.data)
        this.dialog=false
      }).finally(()=>{
        this.saving=false
      })
    }

  },
  components: {
    draggable,
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.skillsCopy = JSON.parse(JSON.stringify(this.skills))
        this.skillsList =  this.skillsCopy.filter(el=>el.type=='um')
        this.knowledgeList = this.skillsCopy.filter(el=>el.type=='zn')
      }
    }
  }
}
</script>