<template>
      <v-card>
        <v-card-title class="white--text" style="background-color:#365d84">
        <v-row align="center">
          <v-col>
            Предварительный просмотр
          </v-col>
          <v-col cols="auto">
            <v-btn color="red" dark small fab @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>

        </v-card-title>

        <v-divider />
        <v-card-text class="black--text" v-if="!loading">

          <v-row align="center" class="mt-10">
           <v-spacer/>
            <v-col class="text-center" cols="auto">
              <div class="text-body-1">
                УТВЕРЖДЁН<br>
                приказом Министерства<br>
                труда и социальной защиты<br>
                Российской Федерации<br>
                от {{ list.dateOfApproval }}
                № {{ list.orderNumber }}
              </div>
            </v-col>
          </v-row>

          <div style="width: 100%" class="text-center mt-16">
            <div class="text-body-1">
              {{ list.name }}
            </div>
            <div class="text-body-1" >
              Регистрационный номер: {{ list.registrationNumber }}
            </div>
          </div>

          <div class="text-h6 mt-5">Общие сведения</div>
          <v-list class="text-body-1">
            Наименование вида профессиональной деятельности:
            <v-list-item>
              {{ list.kindProfessionalActivity }}
            </v-list-item>
            Основная цель вида профессиональной деятельности:
            <v-list-item>
              {{ list.purposeKindProfessionalActivity }}
            </v-list-item>
          </v-list>
          <div class="text-h6 mt-5">Группа занятий</div>
          <table border="1" width="100%">
            <tr>
              <th class="text-center pa-3">Код ОКЗ</th>
              <th class="text-center pa-3">Наименование</th>
            </tr>
            <tr v-for="(okz,index) in list.classes.filter(el=>el.typeId==1)" :key="index">
              <td  class="text-center pa-3">
                {{ okz.code }}
              </td>
              <td  class="text-center pa-3">
                {{ okz.name }}
              </td>
            </tr>
          </table>

          <div class="text-h6 mt-5">Отнесение к видам экономической деятельности</div>
          <table border="1" width="100%">
            <tr>
              <th class="text-center pa-3">Код ОКВЭД</th>
              <th class="text-center pa-3">Наименование</th>
            </tr>
            <tr v-for="(okved,index) in list.classes.filter(el=>el.typeId==2)" :key="index">
              <td  class="text-center pa-3">
                {{ okved.code }}
              </td>
              <td  class="text-center pa-3">
                {{ okved.name }}
              </td>
            </tr>
          </table>

          <div class="text-h6 mt-5"> Описание трудовых функций, входящих в профессиональный стандарт (функциональная
            карта вида профессиональной деятельности)</div>
          <table border="1" width="100%">

            <thead>
            <tr>
              <td class="text-center pa-3">Код</td>
              <td class="text-center pa-3">Наименование</td>
              <td class="text-center pa-3">Уровень квалификации</td>

              <td class="text-center pa-3">Наименование</td>
              <td class="text-center pa-3">Код</td>
              <td class="text-center pa-3">Уровень (подуровень) квалификации</td>
            </tr>
            </thead>
            <tbody>
            <template v-for="item in list.generalWorkFunctions">
              <template v-for="(workName, index) in item.workFunctions">
                <tr :key="'i' + item.id + 'w' + workName.id">
                  <td class="text-center pa-3" v-if="index == 0" :rowspan="item.workFunctions.length">{{
                      item.code
                    }}</td>
                  <td class="text-center pa-3" v-if="index == 0" :rowspan="item.workFunctions.length"><a
                      href="javascript:void(0)" @click="genWorkFuncCurrent = item; genWorkFuncModal = true">{{
                      item.name
                    }}</a></td>
                  <td class="text-center pa-3" v-if="index == 0" :rowspan="item.workFunctions.length">{{
                      item.level
                    }}</td>

                  <td class="text-center pa-3"><a href="javascript:void(0)"
                                                  @click="workFuncModalCurrent = workName; workFuncModal = true">
                    {{ workName.name }}</a></td>
                  <td class="text-center pa-3"> {{ workName.code }}</td>
                  <td class="text-center pa-3"> {{ workName.subQualification }}</td>
                </tr>
              </template>
            </template>
            </tbody>
          </table>

          <div class="text-h6 mt-5">Сведения об организациях – разработчиках профессионального стандарта</div>
          <table border=1  width="100%">
            <tbody>
            <tr class="text-center">
              <td  class="text-center pa-3" colspan=2>{{ list.respDev }}</td>
            </tr>
            <tr class="text-center">
              <td  class="text-center pa-3">{{ list.respDevHeadPos }}</td>
              <td  class="text-center pa-3">{{ list.respDevHeadName }}</td>
            </tr>
            </tbody>
          </table>

          <div class="text-h6 mt-5">Сведения об организациях – разработчиках профессионального стандарта</div>
          <table  border="1"  width="100%">
            <tbody>
            <tr v-for="(org, index) in list.orgDevs" :key="org.id">
              <td  class="text-center pa-3">{{ (index+1) }}</td>
              <td  class="text-center pa-3">{{ org }}</td>
            </tr>
            </tbody>
          </table>


    <v-dialog v-model="genWorkFuncModal" scrollable>
      <genWorkFunc :genWorkFunc="genWorkFuncCurrent" @close="genWorkFuncModal = false" />
    </v-dialog>

    <v-dialog v-model="workFuncModal" scrollable>
      <workFuncModal :workFunc="workFuncModalCurrent" @close="workFuncModal = false" />
    </v-dialog>
    </v-card-text>
        <v-skeleton-loader type="article@5" v-else></v-skeleton-loader>
      </v-card>

</template>


<script>
import genWorkFunc from './genWorkFuncModal'
import workFuncModal from "./workFuncModal";

export default {
    props:['psId'],
  components: {
    genWorkFunc,
    workFuncModal
  },
  watch:{
    psId(){
        this.getData()
    }
  },
  data() {
    return {
      genWorkFuncModal: false,
      workFuncModal: false,
      genWorkFuncCurrent: {},
      workFuncModalCurrent: {},
      list: {},
      loading:true
    }
  },
  methods: {
    getData() {
        this.loading=true
      this.$http.get(this.$http.defaults.profStandUrl+"/v1/external/prof-stand/parsed-get",
        { params: { id: this.psId } }).then((result) => this.list = result.data).finally(()=>{
            this.loading=false
        })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
</style>
