<template>
  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)" flat dark>
      <v-toolbar-title>Профессиональные стандарты</v-toolbar-title
      ><v-spacer></v-spacer>
      <qualificationsSelect :eduProgId="eduProgId"/>
                  <profAddBtn
                  v-if="!loading"
                  :btn="true"
                  :eduOrgId="eduOrgId"
                  :eduProgId="eduProgId"
                  @create="addStand($event)"
            />

    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!loading" class="h-100">
        <v-alert
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      Профессиональные стандарты, с учетом которых разработана образовательная программа
    </v-alert>
          <div v-for="(standard, indexS) in profStands" :key="standard.id">
              <v-list-item   :style="'border-left: '+ $colorsArray[standard.index] + ' solid 15px'" style="border-radius: 8px">
<!--                  <div class="mr-3"-->
<!--                       :class="$colorsArray[standard.index]+' lighten-4'"-->
<!--                       style="height: 100%; width: 10px"></div>-->
                  <v-list-item-content>
                      {{standard.profStandard.name}}
                  </v-list-item-content>
                  <v-list-item-action>
                      <div>
                          <employerAccess :eduProgProfStandId="standard.id"/>

                          <v-btn :id="'selectWorkFunctions'+standard.id" icon color="primary" @click="curEduProgProfStandId=standard.id; functionsSelectModal=true"><v-icon>mdi-school</v-icon></v-btn>
                          <deleteBtn title="Удалить"  :text="'Вы действительно хотите удалить профессиональный стандарт: '+standard.profStandard.name+'. Все связи этого проф. стандарта с компетенциями будут потеряны.'" :actionHref="'edu-prog/prof-stand-delete?id='+standard.id" @remove="profStands.splice(profStands.findIndex(el=>el.id===standard.id),1);$emit('update')"/>
                      </div>
                  </v-list-item-action>
              </v-list-item>
              <v-divider v-if="profStands[indexS + 1]" class="ml-4"/>
          </div>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader 
          type="list-item-two-line@2">

      </v-skeleton-loader>
    </v-card-text>
    <v-dialog v-model="functionsSelectModal" fullscreen scrollable>
      <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title>Выбор используемых трудовых функций из проф. стандарта</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="functionsSelectModal = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <workFunctionsSelect :eduProgProfStandId="curEduProgProfStandId" @update="$emit('update')"/>
      </v-card-text>
      </v-card>
      
    </v-dialog>
    <v-tour name="selectProfStandTour" :steps="selectProfStandSteps" :options="{ highlight: true,labels: {buttonStop: 'ОК' }}"></v-tour>
    <v-tour name="selectWorkFunctionsTour" :steps="selectWorkFunctionsSteps" :options="{ highlight: true,labels: {buttonStop: 'ОК' }}"></v-tour>
  </v-card>
</template>

<style >
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);

}

</style>
<script>
import employerAccess from '@/components/eduProgProfStandards/employerAccess.vue';
import workFunctionsSelect from '@/components/eduProgProfStandards/workFunctions/workFunctionsSelect.vue';
import profAddBtn from '@/components/eduProgProfStandards/profAddBtn'
import deleteBtn from '@/components/delete'
import qualificationsSelect from '@/components/eduProgProfStandards/qualificationsSelect'
export default {
  name: "StarterPage",
  data: () => ({
    loading:false,
    curEduProgProfStandId:null,
    functionsSelectModal:false,
      colorsArray:[
          'red lighten-2',
          'purple lighten-2',
          'indigo lighten-2',
          'blue lighten-2',
          'orange lighten-2',
          'brown lighten-2',
          'green lighten-2',
          'lime lighten-2',
          'cyan lighten-2',
          'deep-orange lighten-2',
      ],
    selectProfStandSteps:[
      {
            target: '#profSelect',  // We're using document.querySelector() under the hood
            header: {
              title: 'Профессиональный стандарт',
            },
            content: `Добавьте профессиональные стандарты, с учетом которых разработана образовательная программа`
          },
          
          ],
    selectWorkFunctionsSteps:[
      {
            target: '.selectWorkFunctions',  // We're using document.querySelector() under the hood
            header: {
              title: 'Трудовые функции',
            },
            content: `Выберите трудовые функции из профессионального стандарта`
          },
          
          ]
  }),
  components: {
    profAddBtn,
    deleteBtn,
    workFunctionsSelect,
    employerAccess,
    qualificationsSelect,
  },
  watch:{
    functionsSelectModal(val){
      if(val==false){
this.$emit('update')
      }
      
    }
  },
  props:['profStands','eduProgId','eduOrgId'],
  methods: {
    addStand(ev){
      this.profStands.push(ev);
      this.selectWorkFunctionsSteps[0].target="#selectWorkFunctions"+ev.id
      this.$tours['selectWorkFunctionsTour'].start()
    },
    fetchData() {
      this.loading=true
      this.$http
        .get("edu-org/info?id="+this.$route.params.id)
        .then((response) => {
          this.eduOrg = response.data.info;
          this.regions = response.data.regions;
        })
        .catch(() => {
          alert("Возникла ошибка");
        }).finally(()=>{
          this.loading=false
        });
    },
  },
  mounted() {
    if(this.profStands.length==0){
      this.$tours['selectProfStandTour'].start()
    }
    //this.fetchData();
  },
};
</script>
