<template>
  <div>
    <v-overlay absolute v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="!loading">
      <v-row>
        <v-col cols="1">
          <v-sheet outlined rounded class="pa-1" style="position: sticky; top: 12px">{{ profStand.name }}</v-sheet>
        </v-col>
        <v-col cols="11" class="pa-0">
          <v-sheet outlined class="my-3 py-2 ml-0 pr-3" v-for="generalWorkFunction in profStand.generalWorkFunctions"
            :key="'gf' + generalWorkFunction.id">
            <v-row class="ma-0">
              <v-col cols="2" class="py-1 pr-0">
                <v-sheet outlined style="position: sticky; top: 12px" rounded class="pa-2">
                  <b>ОТФ:</b> {{ generalWorkFunction.name }}
                </v-sheet>
              </v-col>
              <v-col cols="10" class="pa-0">
                <v-row class="ma-0" v-for="workFunction in generalWorkFunction.workFunctions"
                  :key="'wf' + workFunction.id">
                  <v-col cols="3" class="py-1 pr-0">
                    <v-sheet style="position: sticky; top: 12px" elevation="1" outlined rounded
                      class="pa-2 d-flex justify-space-between" v-bind:class="selectedFunctions.findIndex(
                        (el) => el == workFunction.id
                      ) !== -1
                          ? 'deep-purple lighten-4'
                          : ''
                        ">
                      <div><b>ТФ:</b> {{ workFunction.name }}</div>

                      <v-checkbox class="ma-n2 mr-n3 pl-3" :value="workFunction.id" v-model="selectedFunctions"
                        multiple></v-checkbox>
                      <div style="position:absolute; bottom:5px;right:5px">
                        <comment :comment="getComment('workFunction', workFunction)" />
                      </div>
                    </v-sheet>

                  </v-col>
                  <v-col cols="9" class="pa-0">
                    <v-row class="ma-0" v-for="workAction in workFunction.workActions" :key="'wa' + workAction.id">
                      <v-col cols="5" class="py-1">
                        <v-sheet style="position: sticky; top: 12px" outlined rounded class="pa-2"
                          :elevation="checkSelect(workFunction.id) ? 1 : 0" v-bind:class="checkSelect(workFunction.id)
                              ? 'orange lighten-4'
                              : 'orange lighten-5'
                            ">
                          <b>ТД:</b> {{ workAction.name }}
                          <v-btn v-if="editMode" fab x-small
                            style="position: absolute; right: -16px; top: calc(50% - 16px);" color="green lighten-2" dark
                            @click="uzAddDialogOpen(workAction, workFunction)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <br>
                          <div class="text-right">
                            <comment :comment="getComment('workAction', workAction)" />
                            <skills-sort :skills.sync="workAction.skills" :workActionId="workAction.id" v-if="editMode" />
                          </div>
                        </v-sheet>
                      </v-col>
                      <v-col cols="7" class="pa-0">
                        <v-sheet :elevation="checkSelect(workFunction.id) ? 1 : 0" outlined rounded class="pa-2 my-1"
                          v-for="skill in workAction.skills" :key="'s' + skill.id" v-bind:class="[checkSelect(workFunction.id)
                              ? ' lighten-4'
                              : ' lighten-5', skill.type == 'um' ? 'green' : 'blue']
                            ">
                          <b v-if="skill.type == 'um'">У:</b><b v-else>З:</b> {{ skill.name }}

                          <div class="text-right mr-3" v-if="editMode">
                            <v-btn icon x-small color="blue darken-3" @click="skillDialogEditOpen(skill)" v-if="1 !== 1">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <DeleteBtn :actionHref="'prof-stand/skill-delete?id=' + skill.id + '&type=' + skill.type"
                              :text="'Вы действительно хотите удалить:' + skill.name"
                              @remove="workAction.skills.splice(workAction.skills.findIndex(el => el.id == skill.id), 1)">
                            </DeleteBtn>
                          </div>

                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="skillAddComponent" persistent max-width="900px" scrollable>
      <skillAddComponent @add="skillAdd($event)" @addList="fetchData" :profStandId="profStand.id" :td="tdSelected"
        :tf="tfSelected" @close="skillAddComponent = false" :listSkills="tfSelected.skills" :dialog="skillAddComponent">
      </skillAddComponent>
    </v-dialog>
    <v-dialog v-model="dialogSkillEdit" persistent max-width="900px" scrollable>
      <skillEditComponent @close="dialogSkillEdit = false" :skill="skillSelected" :isEdit="editMode"
        @update="Object.assign(skillSelected, $event)"></skillEditComponent>
    </v-dialog>
  </div>
</template>

<script>
import skillAddComponent from "@/components/eduProgProfStandards/workFunctions/skill/skillAdd";
import skillEditComponent from "@/components/eduProgProfStandards/workFunctions/skill/skillEdit";
import DeleteBtn from "@/components/DeleteBtn";
import skillsSort from "@/components/eduProgProfStandards/workFunctions/skill/skillsSort.vue";
import comment from '@/components/eduProgProfStandards/workFunctions/comment'
export default {
  props: ["eduProgProfStandId"],
  data() {
    return {
      profStand: {},
      selectedFunctions: [],
      loading: false,
      comments: [],
      skillSelected: {},
      skillAddComponent: false,
      tfSelected: {},
      tdSelected: {},
      dialogSkillEdit: false,
    };
  },
  components: {
    comment,
    DeleteBtn,
    skillAddComponent,
    skillEditComponent,
    skillsSort
  },
  computed: {
    editMode() {
      if (!this.profStand.id) {
        return false
      }
      if (this.profStand.eduOrgId) {
        return true
      }
      return false
    }
  },
  watch: {
    eduProgProfStandId() {
      this.fetchData();
    },
    selectedFunctions(newVal, oldVal) {
      if (!this.loading) {
        let newElements = newVal.filter(x => !oldVal.includes(x));
        if (newElements.length > 0) {
          this.$http.get(
            "prof-stand/list-add", {
            params: {
              eduProgProfStandId: this.eduProgProfStandId,
              workFunctionId: newElements[0]
            }
          }
          );
        }
        let removeElements = oldVal.filter(x => !newVal.includes(x));
        if (removeElements.length > 0) {
          this.deleteElement(this.eduProgProfStandId, removeElements[0], 0)
        }
      }
    },
  },
  methods: {
    skillAdd(event) {
      this.tdSelected.skills.push(event)
    },
    skillDialogEditOpen(skill) {
      this.dialogSkillEdit = true
      this.skillSelected = skill
    },
    uzAddDialogOpen(td, tf) {
      this.skillAddComponent = true
      this.tdSelected = td
      this.tfSelected = tf
    },
    getComment(type, target) {
      let comment = this.comments.find(el => el.targetId == target.id && type == type)
      if (!comment) {
        comment = { text: '', targetId: target.id }
      }
      return comment
    },
    deleteElement(eduProgProfStandId, workFunctionId, force = 0) {
      this.$http.get(
        "prof-stand/list-remove", {
        params: {
          eduProgProfStandId: eduProgProfStandId,
          workFunctionId: workFunctionId,
          force: force
        }
      }
      ).catch(e => {
        if (e.response) {
          if (e.response.status == 449) {
            if (confirm(e.response.data)) {
              this.deleteElement(eduProgProfStandId, workFunctionId, 1)
            } else {
              this.selectedFunctions.push(workFunctionId)
            }
          }
          else {
            alert('Ошибка!')
          }
        }
      })
    },
    checkSelect(workFunctionId) {
      return (
        this.selectedFunctions.findIndex((el) => el == workFunctionId) !== -1
      );
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("prof-stand/load", {
          params: {
            eduProgProfStandId: this.eduProgProfStandId,
          },
        })
        .then((response) => {
          this.profStand = response.data.profStand;
          this.selectedFunctions = response.data.eduProgProfStandFunct;
          this.comments = response.data.comments;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style></style>