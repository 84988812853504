<template>
  <v-dialog v-model="dialog" width="500" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on" id="profSelect">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2  justify-space-between">
        <span>Добавить профессиональный стандарт</span>
        <v-spacer />
        <v-btn icon large @click="dialog = false"><v-icon large>mdi-close</v-icon></v-btn>
      </v-card-title>


      <v-card-text class="mt-3">
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab>
            Поиск по названию
          </v-tab>
          <v-tab>
            Поиск по ОКСО
          </v-tab>
          <v-tab>
            Поиск по профессии/должности
          </v-tab>
          <v-tab>
            Поиск по содержанию
          </v-tab>
        </v-tabs>
        <!-- <v-tabs-items v-model="tab">
          <v-tab-item> -->
            <v-row>
              <v-col>
                <v-text-field label="Поиск..." v-model="profStandSearch"></v-text-field>
              </v-col>
            </v-row>
            <div class="text-center" v-if="profStandLoading">
            <v-progress-circular
      :width="3"
      
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
            <v-simple-table v-else>
              <tbody>
                <tr v-for="item in profStandList" :key="item.id">
                  <td>
                      <div>{{ item.name }}</div>
                      <div v-if="item.eduOrgId" class="text-caption blue--text"><i>Связи расставлены образовательной организацией</i></div>
                      <div>{{ item.codeKindProfessionalActivity }}</div>
                      
                  </td>
                  <td class="pa-0">
                    <v-btn v-if="item.own" class="mr-3" icon color="primary"
                      @click="detailOwnId = item.id; detailOwnModal = true">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn v-else class="mr-3" icon color="primary" @click="detailId = item.id; detailModal = true">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-0">
                    <v-btn v-if="item.own" color='primary' @click="save(item.id)">Выбрать</v-btn>
                    <v-btn v-else @click="showAlert(item.id)">
                      <v-icon color="orange">mdi-alert-circle-outline</v-icon>Выбрать
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- <v-pagination v-model="page" :length="Math.ceil(count / perPage)"></v-pagination> -->
          <!-- </v-tab-item> -->
          <!-- <v-tab-item>
            <searchByOtf @selectOwn="save($event)" @select="showAlert($event)" :eduOrgId="eduOrgId"
              @view="detailId = $event; detailModal = true" @viewOwn="detailOwnId = $event; detailOwnModal = true" />
          </v-tab-item>
          <v-tab-item>
            <searchByTf @selectOwn="save($event)" @select="showAlert($event)" :eduOrgId="eduOrgId"
              @view="detailId = $event; detailModal = true" @viewOwn="detailOwnId = $event; detailOwnModal = true" />
          </v-tab-item>
          <v-tab-item>
            <searchByWorkActions @selectOwn="save($event)" @select="showAlert($event)"  :eduOrgId="eduOrgId"
              @view="detailId = $event; detailModal = true" @viewOwn="detailOwnId = $event; detailOwnModal = true" />
          </v-tab-item> -->
        <!-- </v-tabs-items> -->


      </v-card-text>
    </v-card>
    <v-dialog v-model="detailModal" scrollable>
      <parsedProfStandView :psId="detailId" @close="detailModal = false" />
    </v-dialog>
    <v-dialog v-model="detailOwnModal" scrollable>
      <psOwnView :psId="detailOwnId" @close="detailOwnModal = false" />
    </v-dialog>
    <v-dialog v-model="alertModal" max-width="600">
      <v-card>
        <v-card-title>
          Необходимо установить связи
        </v-card-title>
        <v-divider />
        <v-card-text class="text-body-1 mt-3">
            После выбора профессионального стандарта необходимо определить трудовые функции, на которые ориентирована образовательная программа.<br/><br/>
            Трудовые функции разбиваются на трудовые действия, которые разбиваются на умения и знания.<br/><br/>
            В случае, если профессиональный стандарт не был разработан с применением <a href="https://ps.ao-nk.ru">Программного комплекса «Модульный конструктор Национальной системы профессиональных квалификаций» </a>, то Вам необходимо будет самостоятельно методически расставить связь умений и знаний с трудовыми действиями (умения и знания выбираются из трудовой) функции. К одному трудовому действию необходимо определить как минимум одно умение и одно знание.<br/><br/>
            Совокупность выбранных трудовых функций позволяет нам понять в каком объеме планируется формирование профессиональной квалификации (в случае, если к выбранному профессиональному стандарту разработаны соответствующие квалификации).<br/><br/>
            Программный комплекс полностью оцифровывает Реестр сведений о проведении независимой оценки квалификации.


<!--          Выбранный профессиональный стандарт не содержит связей с умениями и знаниями. Чтобы использовать этот-->
<!--          профессиональный стандарт перейдите в <a href="https://ps.ao-nk.ru">ИС ПРОФСТАНДАРТ</a>, найдите его по названию и установите связи.-->
          <div class="d-flex justify-space-between">
            <v-btn color="blue white--text" @click="importWithoutConnections(alertId)" class="mt-5 text-body-1" style="text-transform: unset;;">Импортировать, <br>расставлю связи самостоятельно</v-btn>
            <v-btn color="green white--text" @click="alertModal = false" class="mt-5">Понятно</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(onComplete, ms);
  };
};
// import searchByOtf from './profAddBtn/searchByOtf.vue';
// import searchByTf from './profAddBtn/searchByTf.vue';
// import searchByWorkActions from './profAddBtn/searchByWorkActions.vue';
import parsedProfStandView from '@/components/eduProgProfStandards/regView/parsedProfStandView'
import psOwnView from '@/components/eduProgProfStandards/dbView/parsedProfStandView'
export default {
  props: ["btn", "eduProgId", "eduOrgId"],
  data() {
    return {
      tab: 0,
      dialog: false,
      saving: false,
      errors: [],
      profStandSearch: "",
      profStandLoading: false,
      profStandList: [],
      detailModal: false,
      detailOwnModal: false,
      detailId: null,
      detailOwnId:null,
      alertModal: false,
      alertId: null,
      searchString: null,
      page: 1,
      perPage: 20,
      count: 1,
    };
  },
  components: {
    parsedProfStandView,
    psOwnView,
    // searchByOtf,
    // searchByTf,
    // searchByWorkActions
  },
  methods: {
    showAlert(id) {
      this.alertModal = true
      this.alertId = id
    },
    importWithoutConnections(profStandId) {
      this.errors = [];
      this.$set(this, "saving", true);

      this.$http
        .get("prof-stand/import-without-connections", {
          params: {
            profStandId: profStandId,
            eduOrgId: this.eduOrgId,
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.$emit("create", response.data);
          this.dialog = false;
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Ошибка!");
          }
        })
        .finally(() => {
          this.$set(this, "saving", false);
        });
    },
    save(profStandId) {
      this.errors = [];
      this.$set(this, "saving", true);

      this.$http
        .get("edu-prog/prof-stand-add", {
          params: {
            profStandId: profStandId,
            eduProgId: this.eduProgId
          },
        })
        .then((response) => {
          this.$emit("create", response.data);
          this.dialog = false;
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Ошибка!");
          }
        })
        .finally(() => {
          this.$set(this, "saving", false);
        });
    },
    searchData: debounce(function() {
            this.search();
        }, 500),
    async search() {
      let suffix = ['prof-stand-list-by-name','prof-stand-list-by-okso','prof-stand-list-by-position','prof-stand-list'][this.tab]
      this.profStandLoading = true;
      this.profStandList=[]
          this.count =0
      await this.$http
        .get("edu-prog/"+suffix, {
          params: {
            eduOrgId: this.eduOrgId,
            eduProgId: this.eduProgId,
            string: this.searchString,
            page: this.page,
            perPage: this.perPage
          },
        })
        .then((response) => {
          this.profStandList.push(...response.data.items.map(el=>({...el,own:true})));
          this.count += response.data.count
        })
        .catch(() => {
          alert("Ошибка при загрзке списка профессиональных стандартов");
        })
        .finally(() => {
          this.profStandLoading = false;
        });
        this.profStandLoading = true;
      await  this.$http
      .get(this.$http.defaults.profStandUrl+"/v1/external/prof-stand/"+suffix, {
          params: {
            string: this.searchString,
            page: this.page,
            perPage: this.perPage
          },
        })
        .then((response) => {
          this.profStandList.push(...response.data.items);
          this.count += response.data.count
        })
        .catch(() => {
          alert("Ошибка при загрзке списка профессиональных стандартов");
        })
        .finally(() => {
          this.profStandLoading = false;
        });
    },
  },
  mounted() {
    this.searchData()
  },
  watch: {
    page() {
      this.searchData()
    },
    profStandSearch(val) {
      this.page = 1
      // Items have already been requested
      if (this.profStandLoading || val == null) return;
      this.searchString = val
      this.searchData()

    },
  },
};
</script>
