<template>
  <div v-if="eduProg" class="w-100 h-100 d-flex flex-column">
            <profStandards
            @update="$refs.workFunctionList.fetchData()"
          :profStands.sync="eduProg.profStand"
          :eduProgId="eduProg.id"
          :eduOrgId="eduProg.eduOrgId"
        />
        <workFunctions :eduProgId="eduProg.id" ref="workFunctionList"/>
  </div>
</template>

<script>

import profStandards from "@/components/eduProgProfStandards/list.vue"; 
import workFunctions from '@/components/eduProgProfStandards/workFunctions/list'; 
export default {
    props: ['eduProg'],
    components:{
            profStandards,
            workFunctions
    }
}
</script>

<style>

</style>