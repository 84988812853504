<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-account-hard-hat</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span>Доступ работодателя</span>
      </v-card-title>
      <v-card-subtitle>
        Добавить доступ для работодателя в целях конкретизации региональных особенностей трудовых функций профессионального стандарта
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-text-field label="Наименование организации" v-model="form.orgName"></v-text-field>
        <v-text-field label="ФИО ответственного лица" v-model="form.name"></v-text-field>
        <div class="text-center ma-3">
          <v-btn class="primary" @click="updateKey()" :loading="saving"><span v-if="!form.id || form.locked" >Сформировать ссылку</span><span v-else>Обновить доступ</span></v-btn>
        </div>

        <v-alert v-if="form.id && !form.locked" class="my-3" border="top" colored-border type="info" elevation="2" style="line-break:anywhere;">
          <div class="text-body-1">Отправьте эту ссылку и пароль работадателю для получения доступа к добавлению
            региональных собоеннеостей трудовых функций и трудовых действий выбранному профстандарту</div>
          <div class="mt-3"><b>Ссылка:</b> https://sok.ao-nk.ru/employer/{{form.id}}/{{form.hash}}</div>
          <div class="mt-3"><b>Код:</b> {{form.pin}}</div>
          <div class="text-center">
          <v-btn color="red" @click="lock()" :loading="saving" dark class="ma-3">Отозвать доступ</v-btn>
        </div>
        </v-alert>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: true,
      saving:false,
      form: {}
    }
  },
  props: ['eduProgProfStandId'],
  watch:{
    dialog(val){
      if(val==true){
        this.fecthData()
      }
    }
  },
  methods: {
    fecthData() {
      this.loading = true
      this.$http
        .get("prof-stand/employer-access-get", {
          params: {
            eduProgProfStandId: this.eduProgProfStandId
          },
        })
        .then((response) => {
          if(response.data){
            this.form = response.data
          }
          
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Ошибка!");
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    lock(){
      this.saving = true
      this.$http
        .get("prof-stand/employer-access-lock", {
          params: {
            eduProgProfStandId: this.eduProgProfStandId
          },
        })
        .then((response) => {
          this.form = response.data
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Ошибка!");
          }
        })
        .finally(() => {
          this.saving = false
        });
    },
    updateKey() {
      this.saving = true
      this.$http
        .post("prof-stand/employer-access-add", this.form, {
          params: {
            eduProgProfStandId: this.eduProgProfStandId
          },
        })
        .then((response) => {
          this.form = response.data
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            alert("Ошибка!");
          }
        })
        .finally(() => {
          this.saving = false
        });
    }
  }
}
</script>

<style>

</style>