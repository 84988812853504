<template>
  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)" flat dark>
      <v-toolbar-title>Выбранные трудовые функции</v-toolbar-title><v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-text-field class="ma-3" v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
      hide-details></v-text-field>

    <v-card-text v-if="!loading" class="h-100">
      <v-expansion-panels >
        <template v-for="workFunction of eduProgWorkFunctions">



          <v-expansion-panel :key="workFunction.id" style="border-radius: 8px 2px 1px 8px" :style="'border-left: '+ $colorsArray[workFunction.profStandIndex] + ' solid 15px'">
            <v-expansion-panel-header  >
              <div>
                <v-alert class="py-1  ma-0 " border="left"  colored-border color="deep-purple accent-4"
                  elevation="0">
                  <div class="text-subtitle-2">
                    <b>ТФ:</b> {{ workFunction.workFunc.name }}
                  </div>
                </v-alert>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <td :colspan="headers.length">
          <div class="text-left font-weight-medium py-0 pl-0 pr-3">
            <div v-for="action in workFunction.workFunc.workActions" :key="action.id">

              <v-alert class="ma-0 my-3" dense border="left" colored-border color="orange accent-4" elevation="3">
                <div class="text-subtitle-2 pa-3">
                  <b>ТД:</b> {{ action.name }}
                </div>
                <v-simple-table class="text-body-2">
                  <tbody>
                    <tr v-for="skill in action.skills" :key="skill.id" class="lighten-4" :class="skill.type=='um'?'green':'blue'">
                      <td><b v-if="skill.type=='um'">У:</b><b v-else>З:</b>  {{ skill.name }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-alert>


            </div>
          </div>
        </td>
            </v-expansion-panel-content>
          </v-expansion-panel>



          <v-divider :key="'d' + workFunction.id" />
        </template>
      </v-expansion-panels>
    </v-card-text>
    <div v-else class="w-100 h-100 d-flex align-center justify-center">
        <v-progress-circular 
    
      :size="60"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>

  </v-card>
</template>


<script>
export default {
  name: "StarterPage",
  props: ["eduProgId"],
  data: () => ({
    eduProgWorkFunctions: [],
    count: 0,
    expanded: [],
    loading: true,
    search: "",
    headers: [{ text: "Трудовая функция", value: "workFunc" }],
  }),
  methods: {
    searchFilter(value, search, item) {
      return (
        item.workFunc.name
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get(
          "prof-stand/work-functions-list?eduProgId=" +
          this.eduProgId +
          "&page=" +
          this.page +
          "&perPage=" +
          this.itemsPerPage +
          "&string=" +
          this.searchString
        )
        .then((response) => {
          this.eduProgWorkFunctions = response.data.eduProgWorkFunctions;
          this.count = parseInt(response.data.count);
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
