<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title>{{ skill.type == 'um' ? 'УМ' : 'ЗН'}}: {{  skill.name  }}</v-toolbar-title>

        <v-spacer />

        <v-btn fab small class="mr-3" @click="init()" light
          v-if="JSON.stringify(this.skill) != JSON.stringify(this.skillObject)">
          <v-icon>mdi-undo</v-icon>
        </v-btn>
        <v-btn fab small color="blue" class="mr-3" @click="update" :loading="saving">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn fab small color="red" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-divider />
<!--    <div class=" text-center mt-4">-->
<!--      <v-btn-toggle rounded v-model="skillObject.type" color="blue" mandatory>-->
<!--        <v-btn :value="'um'">Умение</v-btn>-->
<!--        <v-btn :value="'zn'">Знание</v-btn>-->
<!--      </v-btn-toggle>-->
<!--    </div>-->
    <v-card-text class="pt-5">
      <v-textarea outlined placeholder="Введите название" label="Название" v-model="skillObject.name" :error="addError.name?true:false" :error-messages="addError.name">
        <template v-slot:append>
          <v-icon :color="skillObject.name ? 'green' : 'red'">{{skillObject.name ? 'mdi-check' : 'mdi-alert'}}</v-icon>
        </template>
      </v-textarea>
        <v-select outlined label="Статус" v-model="skillObject.status_id" :items="status" :error="addError.status_id?true:false" :error-messages="addError.status_id"></v-select>
        <v-textarea outlined placeholder="Введите заметки" label="Заметки" v-model="skillObject.comment" :error="addError.comment?true:false" :error-messages="addError.comment"></v-textarea>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SkillsDialogEdit",
  props:[
    'skill'
  ],
  data(){
    return{
      status: [
        {value: 1 , text: 'Не обработано'},
        {value: 2 , text: 'Требуется уточнение'},
        {value: 3 , text: 'Завершено'}],
      saving: false,
      addError:{},
      skillObject:{}
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    skill:{
        handler() {
          this.init()
        },
        deep: true,
    },
  },
  methods:{

    init() {
      this.skillObject = JSON.parse(JSON.stringify(this.skill))
    },
    update(){
      this.saving = true
      this.$http.post('skill/update?id='+this.skillObject.id, this.skillObject)
          .then(res => {
            this.addError={}
            this.$emit('close')
            this.$emit('update', res.data)
          })
          .catch(error => {
            console.log(error)
            this.addError = error.response.data

          }).finally(() => {
        this.saving = false
      })

    }
  }
}
</script>

<style scoped>

</style>